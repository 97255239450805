// 'use strict';

$(function() {

// ==============================
// Navigation
// ==============================
  var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';

  $('.hamburger').on('click', function(){
    $(this).toggleClass('is-active');
    $('.navigation__list').slideToggle();

    $('.contentsbody').toggleClass('hiddenBody');
    $('.service-carousel').toggleClass('hiddenBody');
    $('.centralmenu').toggleClass('hiddenBody');
    $('.footer').toggleClass('hiddenBody');
  });
  if (window.matchMedia('(max-width:769px)').matches) {
    $('.navigation__list li a').on('click', function(){
      $('.hamburger').toggleClass('is-active');
      $('.navigation__list').slideToggle();
    });
  }

// ==============================
// スクロールでヘッダーサイズを変更
// ==============================
var $win = $(window),
    $header = $('header'),
    animationClass = 'is-animation';

$win.on('load scroll', function() {
  var value = $(this).scrollTop();
  if ( value > 100 ) {
    $header.addClass(animationClass);
  } else {
    $header.removeClass(animationClass);
  }
});

// ==============================
// ページトップ
// ==============================
var pageTop1 = $("#pagetop");
  pageTop1.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
  $(window).scroll(function () {
    if($(this).scrollTop() >= 200) {
      pageTop1.css( "bottom", "24px" );
    } else {
      pageTop1.css( "bottom", "-60px" );
    }
  });


// tab
  $('.js-tab-btn').on('click', function(event){
    event.preventDefault();
    var $this = $(this);

    $this.parent().siblings().removeClass('is-active').end().addClass('is-active');

    var tabId = $this.data('tabid');
    $this.closest('.js-tab').find('.js-tab-body').each(function(){
      var $content = $(this);
      if($content.data('contentid') == tabId){
          $content.removeClass('is-hidden');
      }else{
          $content.addClass('is-hidden');
      }
    });
  });

  // アコーディオン
  // var _touch = ('ontouchstart' in document) ? 'touchstart' : ;
  $(".js-accordion-btn").on('click',function() {
  	$(this).parent(".accordion").toggleClass("on");
  	$(this).next(".js-accordion-body").slideToggle();
  });

  /*-------------------------------------------*/
  /*  入力フォーム 特定のラジオからプルダウン制御
  /*-------------------------------------------*/
  $("input[name='application-service[situation]']").click(function() {
    var num = $("input[name='application-service[situation]']").index(this);
    if (num == 1) {
      $(".period").addClass("on");
      $(".period").find("input").val('');
      $(".period").find("input").attr("disabled", false);
    } else {
      $(".period").removeClass("on");
      $(".period").find("input").attr("disabled", true);
    }
  });

  // チェックされたら押下可能になるボタン

  $('#confirm').prop('disabled', true);
  $('#check').on('change', function() {
  	if ( $(this).prop('checked') == false ) {
  		$('#confirm').prop('disabled', true);
  	} else {
  		$('#confirm').prop('disabled', false);
  	}
  });

});
